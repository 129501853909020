<template>

    <TablePage ref="TablePage" :preserve-load-table-items-on-create="true" main-url="/doc-converter"
               @tableItemsClick="tableItemsClick">

        <template #toolBarRightSide>
            <FileInput ref="uploadMedoMessageFileInput" :multiple="false" accept=".zip" label="Загрузить сообщение МЭДО"
                       @fileChange="uploadMedoMessage"/>
        </template>

        <template #tableColumns="TableProps">

            <Column :sortable="true" field="doc_date">
                <template #header>
                    <SearchDate v-model="TableProps.filters['doc_date']" label="Дата документа"/>
                </template>
                <template #body="slotProps">
                    {{ dateToDMY(slotProps.data?.['doc_date'] * 1000) }}
                </template>
            </Column>

            <Column :sortable="true" field="doc_number" filter-field="doc_number">
                <template #header>
                    <SearchInput v-model="TableProps.filters['doc_number']" label="Номер документа"/>
                </template>
            </Column>

            <Column :sortable="true" field="doc_executor" filter-field="doc_executor">
                <template #header>
                    <SearchInput v-model="TableProps.filters['doc_executor']" label="Исполнитель"/>
                </template>
            </Column>

            <Column :sortable="true" field="doc_signer" filter-field="doc_signer">
                <template #header>
                    <SearchInput v-model="TableProps.filters['doc_signer']" label="Подписант"/>
                </template>
            </Column>

            <Column :sortable="true" field="destinations" filter-field="destinations">
                <template #header>
                    <SearchInput v-model="TableProps.filters['destinations']" label="Получатели"/>
                </template>
                <template #body="ColumnProps">
                    <ul>
                        <li v-for="destination in ColumnProps.data.destinations" :key="destination">{{ destination }}
                        </li>
                    </ul>
                </template>
            </Column>

            <Column :sortable="true" field="decision_type" filter-field="decision_type">
                <template #header>
                    <SearchSelect
                        v-model="TableProps.filters['decision_type']"
                        :options="decision_types"
                        option-label="key"
                        option-value="value"
                        placeholder="Статус"/>
                </template>
                <template #body="slotProps">
                        <span>{{
                                getObjectFromArrayByPropertyValue(decision_types, 'value', slotProps.data.decision_type).key || 'Статус неизвестен'
                            }}</span>
                </template>
            </Column>


        </template>

    </TablePage>

    <DocumentsConverterCard ref="DocumentsConverterCard" @needUpdateCardInfo="onNeedUpdateCardInfo"/>

</template>

<script>
import {
    DataFilters,
    FileInput,
    Helper,
    SearchDate,
    SearchInput,
    SearchSelect,
    TablePage
} from "@xnpmpackages/xcomponents";
import DocumentsConverterCard from "@/components/app/DocumentsConverter/DocumentsConverterCard";


export default {
    name: "DocumentsConverter",
    mixins: [Helper, DataFilters],
    data() {
        return {

            decision_types: [
                {key: 'Любой', value: null},
                {key: 'Новый контейнер', value: 0},
                {key: 'Отправлен измененный контейнер', value: 1},
                {key: 'Отправлен оригинальный контейнер', value: 2},
                {key: 'В СЭД отправлена квитанция об отказе', value: 3},
            ],

            selectedDocument: null,


        }
    },
    methods: {

        async onNeedUpdateCardInfo() {

            await this.$refs.TablePage.getTableRows(true);
            const newCard = Helper.methods.getObjectFromArrayByPropertyValue(this.$refs.TablePage.$data.tableItems, 'id', this.selectedDocument.id);
            if (newCard) {
                this.tableItemsClick({data: newCard});
            } else {
                await this.$refs.DocumentsConverterCard.$refs.XModal.hide();
            }

        },

        tableItemsClick($event) {
            this.selectedDocument = Helper.methods.copyObjectByJSON($event.data);
            this.$refs.DocumentsConverterCard.show(this.selectedDocument);
        },

        async uploadMedoMessage($file) {

            if (!$file)
                return;

            this.$refs.uploadMedoMessageFileInput.removeFile();

            try {

                this.$xapi.showBlockModal('Загрузка сообщения...');

                let formData = new FormData();
                formData.append('action', 'uploadMedoMessage');
                formData.append('messageZip', $file);

                await this.$xapi.postForm(
                    '/doc-converter',
                    formData,
                );

                this.$xapi.xnotify('Сообщение добавлено в очередь на обработку');
                this.$refs.TablePage.getTableRows(true);

            } finally {
                this.$xapi.hideBlockModal();
            }

        }

    },
    mounted() {
        this.$refs.TablePage.filters = {decision_type: 0};
        this.$refs.TablePage.getTableRows(true);
    },
    components: {FileInput, DocumentsConverterCard, SearchInput, TablePage, SearchSelect, SearchDate}
}
</script>

<style scoped>

</style>